import {
	GET_EMPLOYEES,
	GET_EMPLOYEES_SUCCESS,
	GET_EMPLOYEES_ERROR,

	SAVE_EMPLOYEE,
	SAVE_EMPLOYEE_SUCCESS,
	SAVE_EMPLOYEE_ERROR,

	EMPLOYEE_BLOCK,
	EMPLOYEE_BLOCK_SUCCESS,
	EMPLOYEE_BLOCK_ERROR,

	SAVE_EMPLOYEE_BY_BULK,
	SAVE_EMPLOYEE_BY_BULK_SUCCESS,
	SAVE_EMPLOYEE_BY_BULK_ERROR,

	GET_EMPLOYEE,
	GET_EMPLOYEE_SUCCESS,
	GET_EMPLOYEE_ERROR,

	GET_COMPANY_EMPLOYEE,
	GET_COMPANY_EMPLOYEE_SUCCESS,
	GET_COMPANY_EMPLOYEE_ERROR,

	DELETE_EMPLOYEE,
	DELETE_EMPLOYEE_SUCCESS,
	DELETE_EMPLOYEE_ERROR,

	UPDATE_EMPLOYEE,
	UPDATE_EMPLOYEE_SUCCESS,
	UPDATE_EMPLOYEE_ERROR,
	CLEAR_TEAM_SUCCESS,
	CLEAR_EMPLOYEE_SUCCESS,

	CREATE_EMPLOYEE_AUDIT_LOG,
	CREATE_EMPLOYEE_AUDIT_LOG_SUCCESS,
	CREATE_EMPLOYEE_AUDIT_LOG_ERROR,

	GET_ALL_EMPLOYEES,GET_ALL_EMPLOYEES_SUCCESS,GET_ALL_EMPLOYEES_ERROR,

	GET_FILTERATION_DATA_EMPLOYEE,
	GET_FILTERATION_DATA_EMPLOYEE_SUCCESS,
	GET_FILTERATION_DATA_EMPLOYEE_ERROR,
	RESET_FILTERATION_DATA,
	RESET_FILTERATION_DATA_SUCCESS,

	GET_FILTERATION_DATA_EMPLOYEE_BY_USERID,
	GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS,
	GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR

} from "../actions";

export const getAllEmployees = () => ({
	type: GET_ALL_EMPLOYEES,
});

export const getAllEmployeesSuccess = (employees) => ({
	type: GET_ALL_EMPLOYEES_SUCCESS,
	payload: employees,
});

export const getAllEmployeesError = (message) => ({
	type: GET_ALL_EMPLOYEES_ERROR,
	payload: { message },
})

export const getEmployees = (data) => ({
	type: GET_EMPLOYEES,
	payload: data,
});

export const getEmployeesSuccess = (employees) => ({
	type: GET_EMPLOYEES_SUCCESS,
	payload: employees,
});

export const getEmployeesError = (message) => ({
	type: GET_EMPLOYEES_ERROR,
	payload: { message },
})

export const getEmployeesByCompany = () => ({
	type: GET_COMPANY_EMPLOYEE,
	// payload: { id },
});
export const getEmployeesByCompanySuccess = (employees) => ({
	type: GET_COMPANY_EMPLOYEE_SUCCESS,
	payload: employees,
});

export const getEmployeesByCompanyError = (message) => ({
	type: GET_COMPANY_EMPLOYEE_ERROR,
	payload: { message },
});


export const saveEmployee = (employee,companyId) => ({
	type: SAVE_EMPLOYEE,
	payload: { employee,companyId },
});
export const saveEmployeeSuccess = (message) => ({
	type: SAVE_EMPLOYEE_SUCCESS,
	payload: {message},
});

export const saveEmployeeError = (message) => ({
	type: SAVE_EMPLOYEE_ERROR,
	payload: { message },
});
export const saveEmployeeByBulk = (data,companyId) => ({
	type: SAVE_EMPLOYEE_BY_BULK,
	payload: { data,companyId },
});

export const saveEmployeeByBulkSuccess = (message) => ({
	type: SAVE_EMPLOYEE_BY_BULK_SUCCESS,
	payload: {message},
});

export const saveEmployeeByBulkError = (message) => ({
	type: SAVE_EMPLOYEE_BY_BULK_ERROR,
	payload: { message },
});

export const getEmployee = (id) => ({
	type: GET_EMPLOYEE,
	payload: { id },
});

export const getEmployeeSuccess = (employee) => ({
	type: GET_EMPLOYEE_SUCCESS,
	payload: employee,
});

export const getEmployeeError = (message) => ({
	type: GET_EMPLOYEE_ERROR,
	payload: { message },
});

export const updateEmployee = (employee) => ({
	type: UPDATE_EMPLOYEE,
	payload: { employee },
});

export const updateEmployeeSuccess = (message) => ({
	type: UPDATE_EMPLOYEE_SUCCESS,
	payload: {message},
});

export const updateEmployeeError = (message) => ({
	type: UPDATE_EMPLOYEE_ERROR,
	payload: { message },
});

export const updateEmployeeBlock = (employee) => ({
	type: EMPLOYEE_BLOCK,
	payload: { employee },
});

export const updateEmployeeBlockSuccess = (message) => ({
	type: EMPLOYEE_BLOCK_SUCCESS,
	payload: {message},
});

export const updateEmployeeBlockError = (message) => ({
	type: EMPLOYEE_BLOCK_ERROR,
	payload: { message },
});

export const deleteEmployee = (id,companyId) => ({
	type: DELETE_EMPLOYEE,
	payload: { id,companyId },
});

export const deleteEmployeeSuccess = (id) => ({
	type: DELETE_EMPLOYEE_SUCCESS,
	payload: {id},
});

export const deleteEmployeeError = (message) => ({
	type: DELETE_EMPLOYEE_ERROR,
	payload: { message },
});

export const clearTeamSuccess = () => ({
	type: CLEAR_TEAM_SUCCESS,
});

export const clearEmployeeSuccess = () => ({
	type: CLEAR_EMPLOYEE_SUCCESS,
});



export const getFilterDataEmployee = (filterName) => {
	return{
		type: GET_FILTERATION_DATA_EMPLOYEE,
		payload:  {filterName} ,
	}
	
};
export const getFilterDataEmployeeSuccess = (employees) => {

	
	return {
		type: GET_FILTERATION_DATA_EMPLOYEE_SUCCESS,
		payload:  employees,
    };
};

export const getFilterDataEmployeeError = (message) => {
	

	return {
		type: GET_FILTERATION_DATA_EMPLOYEE_ERROR,
		payload:  {message} ,
    };
};


export const resetFiltersEmployee= (employees) => {
	return{
		type: RESET_FILTERATION_DATA,
		payload:  {employees} ,
	}	
	
};


export const filterResetEmployeeSuccess= (employees) => {
	return{
		type: RESET_FILTERATION_DATA_SUCCESS,
		payload:  employees ,
	}	
	
};

export const getFilterDataEmployeeByUserId = (userId) => {
	return{
		type: GET_FILTERATION_DATA_EMPLOYEE_BY_USERID,
		payload:  {userId} ,
	}
	
};
export const getFilterDataEmployeeByUserIdSuccess = (employees) => {

	
	return {
		type: GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS,
		payload:  employees,
    };
};

export const getFilterDataEmployeeByUserIdError = (message) => {
	

	return {
		type: GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR,
		payload:  {message} ,
    };
};




