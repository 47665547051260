import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { appRoot, loginPage } from "constants/defaultValues";
import { setCurrentUser, getCurrentUser } from "helpers/Utils";
import { getAccessToken } from "helpers/Utils";
import jwt_decode from "jwt-decode";

import axios from "axios";
import { servicePath } from "constants/defaultValues";
import {
  LOGIN_USER,
  GET_USER_INFO,
  GET_USER_INFO_RESET_CODE,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGNUP_USER,
  UPDATE_USER_INFO,
  SET_PASSWORD,
  PASSWORD_UPDATED_SUCCESSFULLY,
  LOGIN_WITH_GMAIL,
  HIDE_PROFILE_LOADER,
  RESEND_VERIFICATION,
  EMAIL_VERIFICATION
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  signupUserSuccess,
  signupUserError,
  setUserProfile,
  updateUserInfoSuccess,
  resendVerificationSuccess,
  getUserInfo,
  resendVerificationError,
  verifyYourEmailSuccess,
  verifyYourEmailError
} from "./actions";
import { getCard } from "../widgetsForm/actions";
import { CloseFullscreen } from "@mui/icons-material";
import SimpleAlert from "components/common/Swal Alert";
import { getIdfromToken } from "helpers/Utils";
import instance from "redux/instance/instanceApi";
// import { createAuditLogAsync } from "helpers/Utils";

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginWithGmail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_WITH_GMAIL, loginWithGmail);
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserInformation);
}
export function* watchGetUserInfoResetCode() {
  yield takeEvery(GET_USER_INFO_RESET_CODE, getUserInformationResetCode);
}

export function* watchUpdateUserInfo() {
  yield takeEvery(UPDATE_USER_INFO, updateUserInformation);
}

export function* watchSetPassword() {
  yield takeEvery(SET_PASSWORD, setPassword);
}

export function* watchSignUpUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SIGNUP_USER, signupWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  let param = {
    password: password,
    email: email,
  };

  try {
    let response = await instance.post("/login", param);

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const loginWithGmailAsync = async (param) => {
  try {
    let response = await instance.post("/externallogin", param);

    return response.data;
  } catch (error) {}
};

const getUserInformationAsync = async () => {
  try {
    const currentuserID = getIdfromToken();

    
    let response = await instance.get(  "/users/" + currentuserID?._id);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getUserInformationResetCodeAsync = async () => {
  try {
    const currentuserID = getIdfromToken();

    
    let response = await instance.get(  "/usersforpassword/" + currentuserID?._id);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const updateUserInformationAsync = async (payload) => {
  try {
    const currentuserID = getIdfromToken();
   
    let response = await instance.post("/users/" + currentuserID?._id, payload);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const setPasswordAsync = async (payload) => {
  try {
    let response = await instance.post("/setPassword", payload);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const signupWithEmailPasswordAsync = async (
  email,
  password,
  username,
  isGuestUser
) => {
  let param = {
    password: password,
    email: email,
    username: username,
    isGuestUser: isGuestUser,
  };
  try {
    let response = await axios.post(servicePath + "/register", param);
    
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const actionName = "Login";
    const description = "logged In successfully";

    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    
    
    if (!loginUser.message) {
      // yield call(
      //   createAuditLogAsync,
      //   actionName,
      //   description
      // );
      // const companyData = yield call (getCompanyAsync);
      // if()
      
      setCurrentUser(loginUser);
      yield put(loginUserSuccess(loginUser)); 
      
      

      if (loginUser?.user?.roleId === 1) {
        history.push(appRoot + "/companyInfo");
        SimpleAlert("success","logged In successfully");
      } else  if (loginUser?.user?.roleId === 5) {
        history.push(appRoot + "/companies");
        SimpleAlert("success","logged In successfully");
      } else  {
        history.push(appRoot);
        SimpleAlert("success","logged In successfully");
      }
    } else {

      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

const getCompanyAsync = async () => {
  try {
      
      let response = await instance.get('/company/owner');
      return response.data;
  } catch (error) {
      // return error.response.data 
      // SimpleAlert("error",`${error?.response?.data?.message}`)
      return error?.response.data;

  }
};

function* loginWithGmail({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithGmailAsync, payload.user);

    if (!loginUser.message) {
      setCurrentUser(loginUser);
      yield put(loginUserSuccess(loginUser));
      history.replace(appRoot);
      SimpleAlert("success","logged In successfully");
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* getUserInformation() {
  try {
    const user = yield call(getUserInformationAsync);

    if (!user.message) {
      yield put(setUserProfile(user));
      // history.push(appRoot);
    } else {
      yield put(loginUserError(user.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* getUserInformationResetCode() {
  try {
    const user = yield call(getUserInformationResetCodeAsync);

    if (!user.message) {
      yield put(setUserProfile(user));
      // history.push(appRoot);
    } else {
      yield put(loginUserError(user.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* updateUserInformation({ payload }) {
  try {
    const currentUser = getCurrentUser();
    const user = yield call(updateUserInformationAsync, payload);
    const tokenId = getIdfromToken();
    

    if (tokenId) {
      yield put(getCard(tokenId?._id));
      yield put(getUserInfo());
    }

    if (user.message === "User updated successfully!") {
      // yield call(createAuditLogAsync,actionName,description);

      const updatedUser = {
        accessToken: currentUser.accessToken,
        user: { ...currentUser.user, ...payload },
      };
      const actionName= "Update profile Info"
      const description = `Update profile Info Id:${tokenId?._id}`
      SimpleAlert("success","User updated successfully!");
      setCurrentUser(updatedUser);
      yield put({ type: HIDE_PROFILE_LOADER });
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserError(error));
  }
}

function* setPassword({ payload }) {
  try {
    const user = yield call(setPasswordAsync, payload);

    if (!user.message) {
      yield put({ type: PASSWORD_UPDATED_SUCCESSFULLY });
    } else {
      yield put(loginUserError(user.message));
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserError(error));
  }
}

function* signupWithEmailPassword({ payload }) {
  const { email, password, username, isGuestUser } = payload.user;
  const { history } = payload;
  try {
    const response = yield call(
      signupWithEmailPasswordAsync,
      email,
      password,
      username,
      isGuestUser
    );
    if (response.message === "User Created successfully!") {
      yield put(signupUserSuccess(response));
      console.log("here");
      // history.push(loginPage);
    } else {
      console.log("hereasdfas");
      yield put(signupUserError(response.message));
    }
  } catch (error) {
    console.log("herasdfwe", error);
    yield put(signupUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
  // window.location.reload();
}

const logoutAsync = async (history) => {

  // localStorage.removeItem("token");
  history.replace(loginPage);
  localStorage.clear();

 
  SimpleAlert("success","Logged Out Successfully");

};

function* logout({ payload }) {
  const { history } = payload;
  const currentUser = getCurrentUser();
  const actionName= "User Logout";
  const description = `UserLogout Info `
  yield call(logoutAsync, history);
  // yield call(createAuditLogAsync,actionName,description);

 
}

export function* watchForgotPassword() {
  console.log("asdfa");
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  let param = {
    email: email,
  };

  try {
    let response = await instance.post("/forgotpassword", param);
    return response;
  } catch (error) {
    console.log("error ===============", error.response);

    return error;
  }
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const response = yield call(forgotPasswordAsync, email);

    if (response?.data?.status) {

      yield put(forgotPasswordSuccess(response?.data?.message));
    } else {

      yield put(forgotPasswordError(response?.data?.message));
    }
  } catch (error) {
    console.log("error ====forgotPassword====", error);
    
    yield put(forgotPasswordError(error));
  }
}

export function* watchResendVerification() {
  console.log("asdfa");
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESEND_VERIFICATION, resendVerification);
}

const resendVerificationAsync = async (email) => {
  let param = {
    email: email,
  };

  try {
    let response = await instance.post("/resendVerification", param);

    return response;
  } catch (error) {
    console.log("error ===============", error.response);
    return error;
  }
};

function* resendVerification({ payload }) {
  const email = payload.resendVerificationMail;
  try {
    const response = yield call(resendVerificationAsync, email);
    if (response.data?.status) {
      yield put(resendVerificationSuccess(response.data.message));
    } else {
      yield put(resendVerificationError(response.data.message));
    }
  } catch (error) {
    yield put(resendVerificationError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  consol.log("payload resetPassword",payload)
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );

    if (!resetPasswordStatus) {
     
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}


export function* watchVerificationYourEmail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(EMAIL_VERIFICATION, emailVerification);
}

function* emailVerification({ payload }) {
  // const { newPassword, resetPasswordCode } = payload;
  try {
    const responseVerify = yield call(
      emailVerificationAsync,
      payload
    );

    if (responseVerify.success) {
     
      yield put(verifyYourEmailSuccess(responseVerify.message));
    } else {
            yield put(verifyYourEmailError(responseVerify.message));

    }
  } catch (error) {
    yield put(verifyYourEmailError(error));

  }
}


const emailVerificationAsync = async ( payload) => {
  try {
    const {id} = payload
 

 
    let response = await instance.get(`/verify/${id}`);
    return response.data

  } catch (error) {
    console.log("error===============emailVerificationAsync", error.response);
  }
};




export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchVerificationYourEmail),
    fork(watchLoginWithGmail),
    fork(watchSetPassword),
    fork(watchGetUserInfo),
    fork(watchGetUserInfoResetCode),
    fork(watchUpdateUserInfo),
    fork(watchSignUpUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchResendVerification),
  ]);
}
