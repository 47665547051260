import 'assets/css/vendor/bootstrap.min.css';

// Themes
// Themes
import 'styles/themes/Onyx.scss';
import 'styles/themes/BrightGray.scss';



// custom styles
import 'styles/scss/bootstrapoverride.scss';
import 'styles/scss/custom.scss';
import _ from 'lodash';
window._ = _;

const render = () => {
  require('./AppRenderer');
};

render();
