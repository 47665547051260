/* AUTH */
export const LOGIN_USER = "LOGIN_USER";



export const LOGIN_WITH_GMAIL = "LOGIN_WITH_GMAIL";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_RESET_CODE = "GET_USER_INFO_RESET_CODE";

export const SET_USER_INFO = "SET_USER_INFO";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const SIGNUP_USER = "SIGNUP_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const HIDE_PROFILE_LOADER = "HIDE_PROFILE_LOADER";
export const SET_PASSWORD = "SET_PASSWORD";
export const RESET_PASSWORD_UPDATED = "RESET_PASSWORD_UPDATED";
export const PASSWORD_UPDATED_SUCCESSFULLY = "PASSWORD_UPDATED_SUCCESSFULLY";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESEND_VERIFICATION = "RESEND_VERIFICATION";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_ERROR = "RESEND_VERIFICATION_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";


/* Wigets Form */

export const WIGETS_FORM_SET = "WIGETS_FORM_SET";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_ACTION = "SET_LOADING_ACTION";
export const SET_LOADING_PREVIEW_WIDGET = "SET_LOADING_PREVIEW_WIDGET";


export const WIGETS_FORM_REARRANGE = "WIGETS_FORM_REARRANGE";
export const SET_PROFILE_WIDGET = "SET_PROFILE_WIDGET";
export const WIGETS_FORM_GET = "WIGETS_FORM_GET";
export const WIGETS_FORM_UPDATE = "WIGETS_FORM_UPDATE";

export const CLEAR_WIDGET_FORM_SUCCESS = "CLEAR_WIDGET_FORM_SUCCESS";


export const SAVE_CARD = "SAVE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const UPDATE_CARD_WIDGET = "UPDATE_CARD_WIDGET";


export const UPDATE_COMPANY_CARD = "UPDATE_COMPANY_CARD";
export const SET_USERNAME_EXIST_ERROR = "SET_USERNAME_EXIST_ERROR";
export const GET_CARD = "GET_CARD";
export const GET_CARD_BY_USERNAME = "GET_CARD_BY_USERNAME";
export const SET_WIDGETS_FORM_DATA = "SET_WIDGETS_FORM_DATA";
export const SET_USERPROFILE_LINK = "SET_USERPROFILE_LINK";
export const SET_PROFILE_THEME = "SET_PROFILE_THEME";

/* Templates */
export const TEMPLATES_GET = "TEMPLATES_GET";
export const TEMPLATES_GET_SUCCESS = "TEMPLATES_GET_SUCCESS";
export const TEMPLATES_GET_ERROR = "TEMPLATES_GET_ERROR";

export const ICONS_GET = "ICONS_GET";
export const ICONS_GET_SUCCESS = "ICONS_GET_SUCCESS";
export const ICONS_GET_ERROR = "ICONS_GET_ERROR";

/* Analytics */

export const GET_ANALYTICS_LISTING = "GET_ANALYTICS_LISTING";
export const GET_ANALYTICS_LISTING_SUCCESS = "GET_ANALYTICS_LISTING_SUCCESS";
export const GET_ANALYTICS_LISTING_ERROR = "GET_ANALYTICS_LISTING_ERROR";

export const GET_ANALYTICS_GRAPHICAL = "GET_ANALYTICS_GRAPHICAL";
export const GET_ANALYTICS_GRAPHICAL_SUCCESS =
  "GET_ANALYTICS_GRAPHICAL_SUCCESS";
export const GET_ANALYTICS_GRAPHICAL_ERROR = "GET_ANALYTICS_GRAPHICAL_ERROR";

export const SAVE_ANALYTICS = "SAVE_ANALYTICS";
export const SAVE_ANALYTICS_SUCCESS = "SAVE_ANALYTICS_SUCCESS";
export const SAVE_ANALYTICS_ERROR = "SAVE_ANALYTICS_ERROR";

/* TEAMS */


export const CLEAR_EMPLOYEE_SUCCESS = "CLEAR_EMPLOYEE_SUCCESS";

export const GET_COMPANY_EMPLOYEE = "GET_COMPANY_EMPLOYEE";
export const GET_COMPANY_EMPLOYEE_SUCCESS = "GET_COMPANY_EMPLOYEE_SUCCESS";
export const GET_COMPANY_EMPLOYEE_ERROR = "GET_COMPANY_EMPLOYEE_ERROR";


export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_EMPLOYEES_SUCCESS = "GET_ALL_EMPLOYEES_SUCCESS";
export const GET_ALL_EMPLOYEES_ERROR = "GET_ALL_EMPLOYEES_ERROR";

export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_ERROR = "GET_EMPLOYEES_ERROR";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";


  export const GET_COMPANY_DETAIL_BY_ID = "GET_COMPANY_DETAIL_BY_ID";
  export const GET_COMPANY_DETAIL_BY_ID_SUCCESS = "GET_COMPANY_DETAIL_BY_ID_SUCCESS";
  export const GET_COMPANY_DETAIL_BY_ID_ERROR = "GET_COMPANY_DETAIL_BY_ID_ERROR";


export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_ERROR = "GET_COMPANY_BY_ID_ERROR";

export const SAVE_COMPANY = "SAVE_COMPANY";
export const SAVE_COMPANY_SUCCESS = "SAVE_COMPANY_SUCCESS";
export const SAVE_COMPANY_ERROR = "SAVE_COMPANY_ERROR";

export const SAVE_COMPANY_BY_SA = "SAVE_COMPANY_BY_SA";
export const SAVE_COMPANY_BY_SA_SUCCESS = "SAVE_COMPANY_BY_SA_SUCCESS";
export const SAVE_COMPANY_BY_SA_ERROR = "SAVE_COMPANY_BY_SA_ERROR";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";
export const EMPLOYEE_BLOCK = "EMPLOYEE_BLOCK";
export const EMPLOYEE_BLOCK_SUCCESS = "EMPLOYEE_BLOCK_SUCCESS";
export const EMPLOYEE_BLOCK_ERROR = "EMPLOYEE_BLOCK_ERROR";

export const SAVE_EMPLOYEE_BY_BULK = "SAVE_EMPLOYEE_BY_BULK";
export const SAVE_EMPLOYEE_BY_BULK_SUCCESS = "SAVE_EMPLOYEE_BY_BULK_SUCCESS";
export const SAVE_EMPLOYEE_BY_BULK_ERROR = "SAVE_EMPLOYEE_BY_BULK_ERROR";

export const SAVE_EMPLOYEE = "SAVE_EMPLOYEE";
export const SAVE_EMPLOYEE_SUCCESS = "SAVE_EMPLOYEE_SUCCESS";
export const SAVE_EMPLOYEE_ERROR = "SAVE_EMPLOYEE_ERROR";

export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";

export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";

export const CREATE_EMPLOYEE_AUDIT_LOG = "CREATE_EMPLOYEE_AUDIT_LOG";
export const CREATE_EMPLOYEE_AUDIT_LOG_SUCCESS =
  "CREATE_EMPLOYEE_AUDIT_LOG_SUCCESS";
export const CREATE_EMPLOYEE_AUDIT_LOG_ERROR =
  "CREATE_EMPLOYEE_AUDIT_LOG_ERROR";

export const GET_FILTERATION_DATA_EMPLOYEE = "GET_FILTERATION_DATA_EMPLOYEE";
export const GET_FILTERATION_DATA_EMPLOYEE_SUCCESS =
  "GET_FILTERATION_DATA_EMPLOYEE_SUCCESS";
export const GET_FILTERATION_DATA_EMPLOYEE_ERROR =
  "GET_FILTERATION_DATA_EMPLOYEE_ERROR";

// deparment
export const SAVE_DEPARTMENT = "SAVE_DEPARTMENT";
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
export const SAVE_DEPARTMENT_ERROR = "SAVE_DEPARTMENT_ERROR";

export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";

export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";
export const CLEAR_DEPARTMENT_SUCCESS = "CLEAR_DEPARTMENT_SUCCESS";


export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_ALL_DEPARTMENT_SUCCESS = "GET_ALL_DEPARTMENT_SUCCESS";
export const GET_ALL_DEPARTMENT_ERROR = "GET_ALL_DEPARTMENT_ERROR";
export const GET_DEPARTMENT_BY_COMPANY = "GET_DEPARTMENT_BY_COMPANY";

export const GET_DEPARTMENT_BY_COMPANY_SUCCESS =
  "GET_DEPARTMENT_BY_COMPANY_SUCCESS";
export const GET_DEPARTMENT_BY_COMPANY_ERROR =
  "GET_DEPARTMENT_BY_COMPANY_ERROR";

export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";


export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_ERROR = "GET_ALL_TEAMS_ERROR";

export const GET_TEAMS_BY_COMPANY = "GET_TEAMS_BY_COMPANY";
export const GET_TEAMS_BY_COMPANY_SUCCESS = "GET_TEAMS_BY_COMPANY_SUCCESS";
export const GET_TEAMS_BY_COMPANY_ERROR = "GET_TEAMS_BY_COMPANY_ERROR";



export const SET_PREVIEW_RIGHT_SIDEBAR = "SET_PREVIEW_RIGHT_SIDEBAR";
export const SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS =
  "SET_PREVIEW_RIGHT_SIDEBAR_SUCCESS";
  
export const CLEAR_TEAM_SUCCESS = "CLEAR_TEAM_SUCCESS";
export const CLEAR_COMPANY_SUCCESS = "CLEAR_COMPANY_SUCCESS";
export const CLEAR_AUTH_SUCCESS = "CLEAR_AUTH_SUCCESS";
export const CLEAR_COMPANY_WIDGETS_SUCCESS = "CLEAR_COMPANY_WIDGETS_SUCCESS";



export const GET_FILTERATION_DATA = "GET_FILTERATION_DATA";
export const GET_FILTERATION_DATA_SUCCESS = "GET_FILTERATION_DATA_SUCCESS";
export const GET_FILTERATION_DATA_ERROR = "GET_FILTERATION_DATA_ERROR";

export const CREATE_DEPARTMENT_AUDIT_LOG = "CREATE_DEPARTMENT_AUDIT_LOG";
export const CREATE_DEPARTMENT_AUDIT_LOG_SUCCESS =
  "CREATE_DEPARTMENT_AUDIT_LOG_SUCCESS";
export const CREATE_DEPARTMENT_AUDIT_LOG_ERROR =
  "CREATE_DEPARTMENT_AUDIT_LOG_ERROR";

// exchangedContacts
export const SAVE_EXCHANGECONTACT = "SAVE_EXCHANGECONTACT";
export const SAVE_EXCHANGECONTACT_SUCCESS = "SAVE_EXCHANGECONTACT_SUCCESS";
export const SAVE_EXCHANGECONTACT_ERROR = "SAVE_EXCHANGECONTACT_ERROR";

export const UPDATE_EXCHANGECONTACT = "UPDATE_EXCHANGECONTACT";
export const UPDATE_EXCHANGECONTACT_SUCCESS = "UPDATE_EXCHANGECONTACT_SUCCESS";
export const UPDATE_EXCHANGECONTACT_ERROR = "UPDATE_EXCHANGECONTACT_ERROR";

export const GET_EXCHANGECONTACT = "GET_EXCHANGECONTACT";
export const GET_EXCHANGECONTACT_SUCCESS = "GET_EXCHANGECONTACT_SUCCESS";
export const GET_EXCHANGECONTACT_ERROR = "GET_EXCHANGECONTACT_ERROR";

export const GET_EXCHANGECONTACT_BY_COMPANY = "GET_EXCHANGECONTACT_BY_COMPANY";
export const GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS =
  "GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS";
export const GET_EXCHANGECONTACT_BY_COMPANY_ERROR =
  "GET_EXCHANGECONTACT_BY_COMPANY_ERROR";
  export const CLEAR_EXCHANGE_CONTACT_SUCCESS = "CLEAR_EXCHANGE_CONTACT_SUCCESS";

export const GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME =
  "GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME";
export const GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS =
  "GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS";
export const GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR =
  "GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR";

export const DELETE_EXCHANGECONTACT = "DELETE_EXCHANGECONTACT";
export const DELETE_EXCHANGECONTACT_SUCCESS = "DELETE_EXCHANGECONTACT_SUCCESS";
export const DELETE_EXCHANGECONTACT_ERROR = "DELETE_EXCHANGECONTACT_ERROR";

export const GET_EXCHANGECONTACT_FILTERATION_DATA =
  "GET_EXCHANGECONTACT_FILTERATION_DATA";
export const GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS =
  "GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS";
export const GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR =
  "GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR";

export const CREATE_EXCHANGECONTACT_AUDIT_LOG =
  "CREATE_EXCHANGECONTACT_AUDIT_LOG";
export const CREATE_EXCHANGECONTACT_AUDIT_LOG_SUCCESS =
  "CREATE_EXCHANGECONTACT_AUDIT_LOG_SUCCESS";
export const CREATE_EXCHANGECONTACT_AUDIT_LOG_ERROR =
  "CREATE_EXCHANGECONTACT_AUDIT_LOG_ERROR";

export const SAVE_TEAM = "SAVE_TEAM";
export const SAVE_TEAM_SUCCESS = "SAVE_TEAM_SUCCESS";
export const SAVE_TEAM_ERROR = "SAVE_TEAM_ERROR";

export const UPDATE_TEAM_DATA = "UPDATE_TEAM_DATA";
export const UPDATE_TEAM_DATA_SUCCESS = "UPDATE_TEAM_DATA_SUCCESS";
export const UPDATE_TEAM_DATA_ERROR = "UPDATE_TEAM_DATA_ERROR";

export const GET_TEAMS = "GET_TEAMS";
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS";
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR";

export const GET_TEAM_BY_ID = "GET_TEAM_BY_ID";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_ERROR = "GET_TEAM_BY_ID_ERROR";

export const GET_FILTERATION_DATA_TEAM = "GET_FILTERATION_DATA_TEAM";
export const GET_FILTERATION_DATA_TEAM_ERROR =
  "GET_FILTERATION_DATA_TEAM_ERROR";
export const GET_FILTERATION_DATA_TEAM_SUCCESS =
  "GET_FILTERATION_DATA_TEAM_SUCCESS";
export const RESET_FILTERATION_DATA_SUCCESS = "RESET_FILTERATION_DATA_SUCCESS";
export const RESET_FILTERATION_DATA = "RESET_FILTERATION_DATA";
export const RESET_FILTERATION_DATA_ERROR = "RESET_FILTERATION_DATA_ERROR";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const CREATE_TEAM_AUDIT_LOG = "CREATE_TEAM_AUDIT_LOG";
export const CREATE_TEAM_AUDIT_LOG_SUCCESS = "CREATE_TEAM_AUDIT_LOG_SUCCESS";
export const CREATE_TEAM_AUDIT_LOG_ERROR = "CREATE_TEAM_AUDIT_LOG_ERROR";

export const GET_AUDIT_LOG = "GET_AUDIT_LOG";
export const GET_AUDIT_LOG_SUCCESS = "GET_AUDIT_LOG_SUCCESS";
export const GET_AUDIT_LOG_ERROR = "GET_AUDIT_LOG_ERROR";

export const GET_AUDIT_LOG_BY_COMPANY = "GET_AUDIT_LOG_BY_COMPANY";
export const GET_AUDIT_LOG_BY_COMPANY_SUCCESS = "GET_AUDIT_LOG_BY_COMPANY_SUCCESS";
export const GET_AUDIT_LOG_BY_COMPANY_ERROR = "GET_AUDIT_LOG_BY_COMPANY_ERROR";

export const GET_FILTERATION_DATA_LOG = "GET_FILTERATION_DATA_LOG";
export const GET_FILTERATION_DATA_LOG_SUCCESS =
  "GET_FILTERATION_DATA_LOG_SUCCESS";
export const GET_FILTERATION_DATA_LOG_ERROR = "GET_FILTERATION_DATA_LOG_ERROR";

export const GET_ROLE_FILTER = "GET_ROLE_FILTER";
export const GET_ROLE_FILTER_SUCCESS = "GET_ROLE_FILTER_SUCCESS";
export const GET_ROLE_FILTER_ERROR = "GET_ROLE_FILTER_ERROR";

export const ADD_DEPARTMENT_ADMIN_DAT_IN_USER = "ADD_DEPARTMENT_ADMIN_DAT_IN_USER";

export const GET_ALL_WIDGET = "GET_ALL_WIDGET";
export const GET_ALL_WIDGET_SUCCESS = "GET_ALL_WIDGET_SUCCESS";
export const GET_ALL_WIDGET_ERROR = "GET_ALL_WIDGET_ERROR";


export const UPDATE_WIDGET = "UPDATE_WIDGET";
export const UPDATE_WIDGET_SUCCESS = "UPDATE_WIDGET_SUCCESS";
export const UPDATE_WIDGET_ERROR = "UPDATE_WIDGET_ERROR";

export const CREATE_WIDGET = "CREATE_WIDGET";
export const CREATE_WIDGET_SUCCESS = "CREATE_WIDGET_SUCCESS";
export const CREATE_WIDGET_ERROR = "CREATE_WIDGET_ERROR";



  export const GET_FILTERATION_DATA_EMPLOYEE_BY_USERID = "GET_FILTERATION_DATA_EMPLOYEE_BY_USERID";
  export const GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS = "GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_SUCCESS";
  export const GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR = "GET_FILTERATION_DATA_EMPLOYEE_BY_USERID_ERROR";


  export const GET_FILTERATION_COMPANY_OWNER_BY_USERID = "GET_FILTERATION_COMPANY_OWNER_BY_USERID";
  export const GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS = "GET_FILTERATION_COMPANY_OWNER_BY_USERID_SUCCESS";
  export const GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR = "GET_FILTERATION_COMPANY_OWNER_BY_USERID_ERROR";

  export const DOWNLOAD_ANALYTICS = "DOWNLOAD_ANALYTICS";
export const DOWNLOAD_ANALYTICS_SUCCESS = "DOWNLOAD_ANALYTICS_SUCCESS";
export const DOWNLOAD_ANALYTICS_ERROR = "DOWNLOAD_ANALYTICS_ERROR";


export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_ERROR = "EMAIL_VERIFICATION_ERROR";

export const GET_ALL_WIDGET_BY_COMPANY = "GET_ALL_WIDGET_BY_COMPANY";
export const GET_ALL_WIDGET_BY_COMPANY_SUCCESS = "GET_ALL_WIDGET_BY_COMPANY_SUCCESS";
export const GET_ALL_WIDGET_BY_COMPANY_ERROR = "GET_ALL_WIDGET_BY_COMPANY_ERROR";

export const DESTROY_SESSION = "DESTROY_SESSION";


export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_ALL_COMPANIES_SUCCESS = "GET_ALL_COMPANIES_SUCCESS";
export const GET_ALL_COMPANIES_ERROR = "GET_ALL_COMPANIES_ERROR";

export const SET_COMPANY_SELECTED = "SET_COMPANY_SELECTED";




export * from "./auth/actions";
export * from "./templates/actions";
export * from "./employees/actions";
export * from "./companies/actions";
export * from "./widgetsForm/actions";
export * from "./admin/actions";

export * from "./analytics/actions";
export * from "./exchangedContacts/actions";
export * from "./role/actions";
export * from "./CompanyWidget/actions";


